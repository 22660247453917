import React, { useEffect } from "react";
import arrows from "../assets/explore.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchHeaders } from "../redux/headerSlice";
import { useTranslation } from "react-i18next";
import { fetchHomeContents } from "../redux/homeSlice";

const Main = () => {
  const dispatch = useDispatch();
  const headers = useSelector((state) => state.headers.headers);
  const headersStatus = useSelector((state) => state.headers.status);
  const error = useSelector((state) => state.headers.error);

  const homeContents = useSelector((state) => state.homeContents.homeContents);
  const homeContentsStatus = useSelector((state) => state.homeContents.status);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (headersStatus === "idle") {
      dispatch(fetchHeaders());
    }
  }, [headersStatus, dispatch]);

  useEffect(() => {
    if (homeContentsStatus === "idle") {
      dispatch(fetchHomeContents());
    }
  }, [homeContentsStatus, dispatch]);

  useEffect(() => {
    if (i18n.language === 'ar') {
      document.body.setAttribute('dir', 'rtl');
    } else {
      document.body.setAttribute('dir', 'ltr');
    }
  }, [i18n.language]);

  if (headersStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  const homeContent = headers?.find((title) => title.name === "home");
  const [mainHeader, subHeader] = homeContent?.value_en.split(
    "Come and Try"
  ) || ["", ""];

  const [mainHeaderAr, subHeaderAr] = homeContent?.value_ar.split(
    "تعال وحاول"
  ) || ["", ""];

  const homeContentDetails = homeContents?.find(
    (content) =>
      content.page === "home" && content.section?.name_en === "header"
  );

  const title =
    language === "en"
      ? homeContentDetails?.title_en
      : homeContentDetails?.title_ar;
  const description =
    language === "en"
      ? homeContentDetails?.desc_en
      : homeContentDetails?.desc_ar;


  return (
    <>
      <div className="px-24 max-lg:px-5 header mb-0" id="hero">
        <div className="content text-center">
          <h1 className="text-6xl max-lg:text-4xl mx-auto lg:w-1/2 text-[#EFEFEF]">
            {language == "en" ? mainHeader.trim() : mainHeaderAr.trim()}
          </h1>
          <p className="text-3xl max-lg:text-lg mt-3 text-[#C7C7C7]">
            {language == "en"
              ? subHeader.trim() || "Come and Try"
              : subHeaderAr.trim() || "تعال وحاول"}
          </p>
          <div className="explore mx-auto mb-5 mt-5 py-3">
            <img
              src={arrows}
              alt="explore"
              className="img-fluid mx-auto"
              width="25px"
            />
          </div>
          <Link to="/rooms" className="book mx-auto mb-5">
            <button
              type="button"
              className="btn text-xl max-lg:text-lg px-10 max-lg:px-5 py-3 max-lg:py-3 text-center text-capitalize tracking-wider"
            >
              {t('Book_Now')}
            </button>
          </Link>
        </div>

        <button className="to-the-top" href="#hero">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-up"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"
            />
          </svg>
        </button>

        <div className="container mt-24 max-lg:mt-14 pt-3 what-are-esc-room text-center">
          <h2 className="text-4xl max-lg:text-2xl font_math">
            {title}
          </h2>
          <p className="text-[#E9E5DD] w-4/5 max-lg:w-full text-center mx-auto mt-5">
            {description}
          </p>
        </div>
      </div>
    </>
  );
};

export default Main;
