import React from "react";
import "../styles/Services.css";
const Servicestitle = ({headtitle}) => {
  return (
    <div className="container-services">
      <div className="overlay">
      <div className="content-services ">
        <h1 className="tracking-wide">{headtitle}</h1>
      </div>
      </div>
    </div>
  );
};

export default Servicestitle;
