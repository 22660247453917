import React, { useEffect } from "react";
import { DoubleLeftOutlined, RightOutlined } from "@ant-design/icons";
import "../../styles/rooms.css";
import { Button } from "antd";
import RoomCard from "./RoomCard";
import explore from "../../assets/explore.png";
import Faq from "../../components/Contact/Faq";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchHeaders } from "../../redux/headerSlice";
const RoomHome = () => {
  const dispatch = useDispatch();
  const headers = useSelector((state) => state.headers.headers);
  const headersStatus = useSelector((state) => state.headers.status);
  const error = useSelector((state) => state.headers.error);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (headersStatus === "idle") {
      dispatch(fetchHeaders());
    }
  }, [headersStatus, dispatch]);

  if (headersStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  const roomsContent = headers?.find((title) => title.name === "rooms");
  const [mainHeader, subHeader] = roomsContent?.value_en.split(
    "Rest assured, they’re all friendly to light hearted... or so we hope"
  ) || ["", ""];

  const [mainHeaderAr, subHeaderAr] = roomsContent?.value_ar.split(
    "كن مطمئنًا ، كلهم ​​ودودون لضوء القلب ... أو هكذا نأمل"
  ) || ["", ""];

  return (
    <>
      <div className="bgAbout"></div>

      {/* <div className="h-[100vh]">
        <div className=" content-news  flex flex-col justify-center items-center h-[60vh]">
          <div className="text-center ">
            <h1 className="text-3xl">DARE TO TRY OUR ROOMS?</h1>
            <p className="text-news text-xs">
              Rest assured, they’re all friendly to light hearted... or so we
              hope
            </p>
          </div>

          <div className="icon-position mx-auto ">
            <div className="explore mx-auto">
              <img src={explore} className="mx-auto img-fluid w-7" />
            </div>
            <p className="text-news-link text-center">EXPLORE</p>{" "}
          </div>
        </div>
      </div> */}

      <div className="h-[100vh] flex flex-col justify-between items-center">
        <div className="content-news flex flex-col justify-center items-center h-[60vh]">
          <div className="text-center">
            <h1 className="text-3xl">
              {language == "en" ? mainHeader.trim() : mainHeaderAr.trim()}
            </h1>
            <p className="text-news text-xs">
              {language == "en"
                ? subHeader.trim() ||
                  "Rest assured, they’re all friendly to light hearted... or so we hope"
                : subHeaderAr.trim() ||
                  "كن مطمئنًا ، كلهم ​​ودودون لضوء القلب ... أو هكذا نأمل"}
            </p>
          </div>
        </div>

        <div className="icon-position flex flex-col items-center mb-4">
          <div className="explore">
            <img src={explore} className="mx-auto img-fluid w-7" />
          </div>
          <p className="text-news-link text-center">{t('EXPLORE')}</p>
        </div>
      </div>
    </>
  );
};

export default RoomHome;
