import React, { useEffect } from "react";
import Trapped from "./Home/Trapped";
import qoute3 from "../assets/qoute3.png";
import qeustion from "../assets/qeustion.png";
import ghost from "../assets/ghost.png";
import vid from "../assets/vid.png";
import video from "../assets/vid.mp4";
import "../styles/Explain.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchHomeContents } from "../redux/homeSlice";

const Explain = () => {
  const dispatch = useDispatch();
  const homeContents = useSelector((state) => state.homeContents.homeContents);
  const homeContentsStatus = useSelector((state) => state.homeContents.status);
  const error = useSelector((state) => state.homeContents.error); // Ensure this is the correct path

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (homeContentsStatus === "idle") {
      dispatch(fetchHomeContents());
    }
  }, [homeContentsStatus, dispatch]);

  if (homeContentsStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error || "Please try again later."}</p>
      </div>
    );
  }

  const homeContentDetails = homeContents?.find(
    (content) => content.page === "home" && content.section?.name_en === "Quote"
  );

  const ExpectDetails = homeContents?.find(
    (content) =>
      content.page === "home" && content.section?.name_en === "Expect"
  );

  const pointDetails = homeContents?.filter(
    (content) => content.page === "home" && content.section?.name_en === "point"
  );

  const QTitle =
    language === "en"
      ? homeContentDetails?.title_en
      : homeContentDetails?.title_ar;

  const ETitle =
    language === "en" ? ExpectDetails?.title_en : ExpectDetails?.title_ar;

  const EDesc =
    language === "en" ? ExpectDetails?.desc_en : ExpectDetails?.desc_ar;

  return (
    <>
      <div className="explain-container h-full relative">
        <div className={`bg_explain Explain ${language === "ar" && "ExplainAR"} relative`}>
          <div className="px-24 max-lg:px-5">
            <h2 className="text-4xl max-lg:text-2xl font_math font-bold primary_color mt-32 max-lg:mt-10">
              {t('Trapped')} 
              <br /> {t('Herehow')} 
            </h2>
            <p className="text-lg text-[#E9E5DD] lg:w-3/4 mt-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud.
            </p>

            <Trapped />

            <div className="relative mt-20">
              <h3 className="text-[#F1EFE6] text-3xl max-lg:text-lg text-center">
                {QTitle}
              </h3>
              <img
                src={qoute3}
                width="40px"
                className="absolute right-64 max-lg:right-0 -top-5 max-lg:w-[20px]"
              />
            </div>

            <img
              src={qeustion}
              width="80px"
              className="absolute left-0 max-lg:w-[40px]"
            />

            <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-8 my-14 lg:items-center">
              <div>
                <video
                  controls
                  width="500px"
                  poster={vid}
                  className="flex mx-auto rounded-2xl bg-black w-[500px] h-[400px] max-lg:w-full"
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <div>
                <h2 className="primary_color text-4xl max-lg:text-2xl font-semibold font_math">
                  {ETitle}
                </h2>
                <p className="second_color mt-4 mb-7">{EDesc}</p>
                <div className="grid grid-cols-2 max-xl:grid-cols-2 gap-4 second_color ml-16 max-lg:ml-8">
                  {pointDetails?.map((point, index) => (
                    <div className="flex items-center mb-2" key={index}>
                      <img src={ghost} width="20px" className="" />
                      <span className="mx-3">
                        {language === "en" ? point.title_en : point.title_ar}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <p className="text-center second_color w-2/3 max-lg:w-full mx-auto">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Explain;