import React, { useEffect } from "react";
import pngwing4 from "../../assets/pngwing 4.png";
import location from "../../assets/location.png";
import tv from "../../assets/tv.png";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchAboutContents } from "../../redux/aboutSlice";
// import Faq from "../Contact/Faq";

const WhyEscape = () => {
  const dispatch = useDispatch();
  const aboutContents = useSelector(
    (state) => state.aboutContents.aboutContents
  );
  const aboutContentsStatus = useSelector(
    (state) => state.aboutContents.status
  );
  const error = useSelector((state) => state.aboutContents.error);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (aboutContentsStatus === "idle") {
      dispatch(fetchAboutContents());
    }
  }, [aboutContentsStatus, dispatch]);

  if (aboutContentsStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  const aboutContentDetailsW = aboutContents?.find(
    (content) =>
      content.page === "about" &&
      content.section?.name_en === "Why escape room?"
  );

  const WTitle =
    language === "en"
      ? aboutContentDetailsW?.title_en
      : aboutContentDetailsW?.title_ar;

  const WDesc =
    language === "en"
      ? aboutContentDetailsW?.desc_en
      : aboutContentDetailsW?.desc_ar;

  const reasons = aboutContents?.filter(
    (content) =>
      content.page === "about" && content.section?.name_en === "reason"
  );

  return (
    <div className="relative">
      <img
        src={pngwing4}
        width="180px"
        className="lg:mr-20 rounded-xl absolute -right-10 max-lg:right-0 -top-44 max-lg:-top-32 z-10 max-lg:w-[80px]"
      />
      <div className="bg_why"></div>
      <div className="flex items-end pt-44 pl-44 max-lg:pl-20 mb-56" dir="ltr">
        <img
          src={location}
          width="500px"
          className="lg:mr-3 rounded-xl max-lg:w-[200px] pb-3"
        />
        <span className="text-[#FFF705] text-2xl max-lg:text-lg">Sohag, Egypt</span>
      </div>
      <div className="px-24 max-lg:px-5">
        <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-4 lg:items-center">
          <div>
            <h2 className="text-4xl max-lg:text-2xl font_math font-semibold text-[#EFEFEF]">
              {WTitle}
            </h2>
            <p className="text-[#E9E5DD] text-lg my-5">{WDesc}</p>
            <div className="grid grid-cols-3 max-xl:grid-cols-1 gap-4 lg:items-center">
              {reasons?.map((reason, index) => (
                <div
                  key={index}
                  className="text-[#E8E361] bg-[#0A0A0A] p-3 rounded-3xl text-xl text-center"
                >
                  {language === "en" ? reason.title_en : reason.title_ar}
                </div>
              ))}
            </div>
          </div>
          <div>
            <img src={tv} className="" />
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between text-[#E9E5DD] lg:mx-20 pt-4 border-t-4 border-[#C7A807]">
            <p>{t("Entertainment")}</p>
            <p>%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyEscape;
