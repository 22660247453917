import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authAPI from '../services/api';
import { Navigate } from 'react-router-dom';

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (userData, thunkAPI) => {
    try {
      const response = await authAPI.register(userData);
      // localStorage.setItem('token', response.data.data.token);
      // localStorage.setItem('user', JSON.stringify(response.data.data.user));
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (userData, thunkAPI) => {
    try {
      const response = await authAPI.login(userData);
      const { token, user } = response.data.data;

      if (token) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        return { token, user };
      } else {
        throw new Error('Invalid login credentials');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || { message: 'An error occurred' });
    }
  }
);

// export const loginUser = createAsyncThunk(
//   'auth/loginUser',
//   async (userData, thunkAPI) => {
//     try {
//       const response = await authAPI.login(userData);
//       const token = response.data.data.token;

//       if (!token == undefined) {
//         localStorage.setItem('token', token);
//         localStorage.setItem('user', JSON.stringify(response.data.data.user));
//         return response.data.data;
//       } else {
//         throw new Error('Invalid login credentials');
//       }
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data);
//     }
//   }
// );

export const createProfile = createAsyncThunk(
  'auth/createProfile',
  async (userData, thunkAPI) => {
    try {
      const response = await authAPI.createProfile(userData);
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (passwordData, thunkAPI) => {
    try {
      const response = await authAPI.resetPassword(passwordData);
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || { message: 'An error occurred' });
    }
  }
);

const token = localStorage.getItem('token');
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: user || null,
    isAuthenticated: !!token,
    isLoading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.isAuthenticated = true;
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        state.isAuthenticated = !!localStorage.getItem('token');
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log(action.payload)
        // state.user = action.payload.user;
        // state.isAuthenticated = true;
      })
      .addCase(createProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        state.isAuthenticated = !!localStorage.getItem('token');
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.error = action.payload;
      });
  },
});

export const { logout , setUser } = authSlice.actions;
export default authSlice.reducer;