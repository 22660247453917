import React, { useEffect } from 'react';
import Main from '../components/Main';
import Explain2 from '../components/Explain2';
import Advertisement from '../components/Advertisement';
import '../styles/main.css';
import '../styles/home.css';
import Rooms_section from '../components/Home/Rooms_section';
import OurServices from '../components/Home/OurServices';
import SwiperComponents from '../components/Contact/SwiperComponents';
import Servicesubtitle from '../components/Servicesubtitle';
import i18n from '../i18n';

const Home = () => {

  return (
    <div className='home'>
      <Main />
      <Explain2 />
      <Rooms_section />
      <OurServices />
      
      <div className='pt-10'>
        <Servicesubtitle headsubtitle="They made it.. Trust in YOU" />
        <SwiperComponents />
      </div>

      <Advertisement />
    </div>
  )
}

export default Home
