import React from "react";
import "../styles/news.css";
import FormInput from "./FormInput";
import "../styles/form-comment.css";
import { useTranslation } from "react-i18next";

const NewsForm = ({ label, ...props }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  return (
    <div>
      <div className="big-container-form">
        <div className="small-container">
          <h2 className="text-center headStyle">HAVE A COMMENT ?</h2>

          <form className="container md:w-3/4 mx-auto">
            <div className="flex flex-wrap justify-center mb-3">
              <div className="w-full md:w-1/2 px-2 mb-3 md:mb-0">
                <div className="relative">
                  <FormInput
                    placeholder="Sohaila"
                    aria-label="First name"
                    className="border rounded w-full py-2 px-3"
                  />
                  <label
                    className={`input-label ${
                      language == "ar" && "input-label-ar"
                    }`}
                  >
                    {"First name"}
                  </label>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-2">
                <div className="relative">
                  <FormInput
                    placeholder="Ali"
                    aria-label="Last name"
                    className="border rounded w-full py-2 px-3"
                  />
                  <label
                    className={`input-label ${
                      language == "ar" && "input-label-ar"
                    }`}
                  >
                    {"Last name"}
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-center mb-3">
              <div className="w-full md:w-1/2 px-2 mb-3 md:mb-0">
                <div className="relative">
                  <FormInput
                    placeholder="Sohailaahmed456@gmail.com"
                    aria-label="Email Addess"
                    className="border rounded w-full py-2 px-3"
                  />
                  <label
                    className={`input-label ${
                      language == "ar" && "input-label-ar"
                    }`}
                  >
                    {"Email Addess"}
                  </label>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-2">
                <div className="relative">
                  <FormInput
                    placeholder="01012345678"
                    aria-label="mobile number"
                    className="border rounded w-full py-2 px-3"
                  />
                  <label
                    className={`input-label ${
                      language == "ar" && "input-label-ar"
                    }`}
                  >
                    {"mobile number"}
                  </label>
                </div>
              </div>
            </div>
            <div className="flex justify-center mb-3">
              <div className="w-full px-2">
                <div className="relative">
                  <FormInput
                    placeholder="Subject / comments / questions"
                    aria-label="Subject / comments / questions"
                    className="border rounded w-full py-2 px-3"
                  />
                  <label
                    className={`input-label ${
                      language == "ar" && "input-label-ar"
                    }`}
                  >
                    {"Subject / comments / questions"}
                  </label>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary btn-lg local py-4 px-16 "
              >
                SEND COMMENT
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewsForm;
