import React from "react";
import "../styles/news.css";

import explore from "../assets/explore.png";

const Newsview = ({name}) => {
  return (
    <div>
      <div className="bg-roomview"></div>
      <div className="h-[100vh]">
        <div className="flex flex-col justify-center items-center h-[60vh]">
          <div className="content-news   text-center  my-auto ">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold">
              {name}
            </h1>
            <p className="text-news text-xs">Trust us... it’s worth the wait</p>
          </div>
          <div className="icon-position mx-auto">
            <div className="explore mx-auto">
              <img src={explore} className="mx-auto img-fluid w-7" />
            </div>
            <p className="text-news-link text-center">EXPLORE</p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsview;
