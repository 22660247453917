import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/Logo-01 2.png";
import {
  FacebookFilled,
  InstagramFilled,
  TikTokFilled,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchContacts } from "../redux/contactSlice";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts.contacts);
  const contactsStatus = useSelector((state) => state.contacts.status);
  const error = useSelector((state) => state.contacts.error);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (contactsStatus === "idle") {
      dispatch(fetchContacts());
    }
  }, [contactsStatus, dispatch]);

  if (contactsStatus === "failed") {
    return <div>Error: {error}</div>;
  }
  // Find specific contact details
  const emailContact = contacts?.find((contact) => contact.type_en === "Email");
  const phoneContact = contacts?.find((contact) => contact.type_en === "Phone");
  const whatsContact = contacts?.find((contact) => contact.type_en === "WhatsApp");
  const facebookContact = contacts?.find(
    (contact) => contact.type_en === "Facebook"
  );
  const instagramContact = contacts?.find(
    (contact) => contact.type_en === "Instagram"
  );
  const tiktokContact = contacts?.find(
    (contact) => contact.type_en === "Tiktok"
  );

  const message = "Hello, I am interested in your services.";
  
  return (
    <>
      <div>
        <hr className="h-[5px] w-3/5 max-lg:w-2/3 border border-[#484848] bg-[#484848] mx-auto" />
        <div className="px-44 max-lg:px-5 relative">
          <img src={Logo} className="w-1/2 absolute right-0 bottom-0 z-0" />
          <div className="grid grid-cols-4 max-xl:grid-cols-2 gap-8 my-40 max-lg:my-10 relative z-10">
            <div>
              <h3 className="text-[#EEE966] text-2xl max-lg:text-xl font-semibold">
                {t('Company')} 
              </h3>
              <div className="mt-3">
                <Link to="/" className="text-[#EFEFEF] lg:text-lg block mt-1">
                  {t('About_Us')}
                </Link>
                <Link to="/" className="text-[#EFEFEF] lg:text-lg block mt-1">
                   {t('Contact_Us')}
                </Link>
                <Link to="/" className="text-[#EFEFEF] lg:text-lg block mt-1">
                   {t('Services')}
                </Link>
                <Link to="/" className="text-[#EFEFEF] lg:text-lg block mt-1">
                   {t('Rooms')}
                </Link>
              </div>
            </div>
            <div>
              <h3 className="text-[#EEE966] text-2xl max-lg:text-xl font-semibold">
                 {t('Contacts')}
              </h3>
              <div className="mt-3">
                {emailContact && (
                  <Link
                    to={`mailto:${emailContact.value}`}
                    className="text-[#EFEFEF] lg:text-lg max-lg:text-sm block mt-1"
                  >
                    {emailContact.value}
                  </Link>
                )}

                {phoneContact && (
                  <Link
                    to={`tel:${phoneContact.value}`}
                    className="text-[#EFEFEF] lg:text-lg block mt-1"
                  >
                    {phoneContact.value}
                  </Link>
                )}

                <p to="/" className="text-[#EFEFEF] lg:text-lg block mt-1">
                   {t('addr')}
                </p>
              </div>
            </div>
            <div>
              <h3 className="text-[#EEE966] text-2xl max-lg:text-xl font-semibold">
                 {t('Help')}
              </h3>
              <div className="mt-3">
                <Link to="/" className="text-[#EFEFEF] lg:text-lg block mt-1">
                   {t('Terms_Conditions')}
                </Link>
                <Link to="/" className="text-[#EFEFEF] lg:text-lg block mt-1">
                   {t('Privacy_Policy')}
                </Link>
              </div>
            </div>
            <div>
              <h3 className="text-[#EEE966] text-2xl max-lg:text-xl font-semibold ml-7 ">
                 {t('Socials')}
              </h3>
              <div className="mt-3 text-[#EFEFEF]">
                {whatsContact && (
                  <Link to={`https://wa.me/${
                    whatsContact.value
                  }?text=${encodeURIComponent(message)}`} className={`${language == "en" ? "mr-4" : "ml-4"} `}>
                    <WhatsAppOutlined className="max-lg:w-[25px]"/>
                  </Link>
                )}
                {facebookContact && (
                  <Link to={facebookContact.value} className={`${language == "en" ? "mr-4" : "ml-4"} `}>
                    <FacebookFilled className="max-lg:w-[25px]"/>
                  </Link>
                )}
                {instagramContact && (
                  <Link to={instagramContact.value} className={`${language == "en" ? "mr-4" : "ml-4"} `}>
                    <InstagramFilled className="max-lg:w-[25px]"/>
                  </Link>
                )}
                {tiktokContact && (
                  <Link to={tiktokContact.value} className={`${language == "en" ? "lg:mr-4" : "lg:ml-4"} `}>
                    <TikTokFilled className="max-lg:w-[25px]"/>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="text-[#EEE966] text-center relative z-10 pb-5">
            &copy; {t('allRights')}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
