import React from 'react'
import MainBook from '../components/BookNow/MainBook'
import BookingForm from '../components/BookNow/BookingForm'

const Booking = () => {
    return (
        <>
            <MainBook />
            <BookingForm />
        </>
    )
}

export default Booking