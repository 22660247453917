import React, { useState } from "react";
import cardpicture from "../assets/cardpicture.png";
import { Link } from "react-router-dom";

function Singlecard({name , desc , link}) {
  const [bgColor, setBgColor] = useState("white");

  const handleClick = () => {
    setBgColor("green");
  };

  return (
    <div className="card">
      <img src={cardpicture} className="card-img-top " alt="..." />
      <div className="card-body p-2 ">
        <div>
          <h5 className="single-card-title text-center mt-3">{name}</h5>
          <p className="card-text text-center text-lg">
            {desc}
          </p>
          <div className="btn-container">
            <Link
              to={`/story/${link}`}
              className="btn-primary-services  "
              onClick={handleClick}
            >
              READ MORE
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Singlecard;
