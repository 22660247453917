import React from 'react'
import Summary from '../components/BookingPay/Summary'

const BookingPay = () => {
  return (
    <div className='px-24 max-lg:px-5'>
      <Summary />
    </div>
  )
}

export default BookingPay