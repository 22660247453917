import React from 'react'
import SignUp from '../components/login/SignUp.js'
const Signup = () => {
  return (
    <>
   <SignUp />
  

   </>
  )
}

export default Signup