import React from "react";

const MainBook = () => {
  return (
    <div className="text-center my-44 max-lg:my-20">
      <h2 className="text-6xl max-lg:text-4xl font_math mx-auto lg:w-1/2 text-[#EFEFEF]">
        ASYLUM BOOKING
      </h2>
      <p className="text-xl max-lg:text-lg mt-3 text-[#E9E5DD]">
        Can you escape The Asylum? Guess we’ll find out
      </p>
    </div>
  );
};

export default MainBook;
