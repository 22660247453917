import React, { useEffect } from "react";
import RoomHome from "../components/Rooms/RoomHome";
import RoomCard from "../components/Rooms/RoomCard";
import Faq from "../components/Contact/Faq";
import { Button } from "antd";
import { DoubleLeftOutlined, RightOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { fetchRooms } from "../redux/roomSlice"
import { useTranslation } from "react-i18next";

const Rooms = () => {
  const dispatch = useDispatch();
  const rooms = useSelector((state) => state.rooms.rooms);
  const roomsStatus = useSelector((state) => state.rooms.status);
  const error = useSelector((state) => state.rooms.error);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (roomsStatus === "idle") {
      dispatch(fetchRooms());
    }
  }, [roomsStatus, dispatch]);
  console.log(rooms);
  const bestRooms = rooms.slice(0,);
  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  return (
    <>
      <RoomHome />
      {bestRooms.map((room, index) => (
        <RoomCard activeLevels={10}
          key={index}
          name={language == "en" ? room.name_en : room.name_ar}
          desc={truncateText(language == "en" ? room.desc_en : room.desc_ar, 150)}
          min={room.min_players}
          max={room.max_players}
          time={room.time_in_minutes}
          image={`${process.env.REACT_APP_BASE_URL}${room.image}`}
          link={room.id}
          rate={room.rate ==! null}
        />
      ))}
      <Faq />
      {/* <div className="flex justify-center my-8 mx-auto"> */}
      {/* <Link
              to="/signup"
              className="sign-up px-2 rounded-3xl border-2 border-[#F4EB19]"
            > */}
      {/* <button className="btn py-3 px-3 text-[#F4EB19] tracking-wider px-4 rounded-3xl   font-semibold border-[#F4EB19] flex items-center" style={{ border: "3px solid " }}>
          VIEW ALL FAQS
          <RightOutlined className="ml-3 size-4" />
        </button> */}
      {/* </Link> */}
      {/* </div> */}
    </>
  );
};

export default Rooms;
