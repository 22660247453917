import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicAxios } from '../api';

const initialState = {
  questions: [],
  status: 'idle',
  error: null,
};

export const fetchQuestions = createAsyncThunk('questions/fetchQuestions', async () => {
  const response = await publicAxios.get('/api/faqs');
  return response.data;
});

const faqSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.questions = action.payload;
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default faqSlice.reducer;