import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchHomeContents } from "../redux/homeSlice";

const Advertisement = () => {
  const dispatch = useDispatch();
  const homeContents = useSelector((state) => state.homeContents.homeContents);
  const homeContentsStatus = useSelector((state) => state.homeContents.status);
  const error = useSelector((state) => state.homeContents.error);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (homeContentsStatus === "idle") {
      dispatch(fetchHomeContents());
    }
  }, [homeContentsStatus, dispatch]);

  if (homeContentsStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  const homeContentDetails = homeContents?.find(
    (content) =>
      content.page === "home" && content.section?.name_en === "Are you ready?"
  );

  const ATitle =
    language === "en"
      ? homeContentDetails?.title_en
      : homeContentDetails?.title_ar;

  // const ADesc =
  //   language === "en" ? homeContentDetails?.desc_en : homeContentDetails?.desc_ar;

  const [ADesc, subADesc] = homeContentDetails?.desc_en.split(
    "everywhere!"
  ) || ["", ""];

  const [ADescAR, subADescAR] = homeContentDetails?.desc_ar.split(
    "في كل مكان!"
  ) || ["", ""];

  return (
    <>
      <div className="px-24 max-lg:px-5 py-20 max-lg:py-10 relative">
        <div className="mx-auto text-center">
          <h5 className="text-[#757575] text-3xl max-lg:text-lg font-semibold	">
            {ATitle}
          </h5>
          <h1 className="mx-auto text-[#E9E5DD] text-5xl max-lg:text-3xl mt-4 font-semibold	">
            {language == "en" ? ADesc.trim() : ADescAR.trim()}
            <span className="text-[#BC3939] block">
              {language == "en"
                ? subADesc.trim() || "everywhere!"
                : subADescAR.trim() || "في كل مكان!"}
            </span>
          </h1>
        </div>
        <div className="mx-auto flex mt-7">
          <Link
            to="/rooms"
            className="my-btn-1 d-block border-0 px-10 py-3 mx-auto rounded-lg bg-[#D6BA2A] text-[#EFEFEF] text-capitalize tracking-wider font-semibold	"
          >
            {t("Book_Now")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default Advertisement;
