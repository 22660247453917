import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicAxios } from '../api';

const initialState = {
  programs: [],
  status: 'idle',
  error: null,
};

export const fetchPrograms = createAsyncThunk('programs/fetchPrograms', async () => {
  const response = await publicAxios.get('/api/programs');
  return response.data;
});

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrograms.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPrograms.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.programs = action.payload;
      })
      .addCase(fetchPrograms.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default programsSlice.reducer;