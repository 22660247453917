import React, { useEffect } from "react";
import ghost from "../../assets/ghost.png";
import Rectangle from "../../assets/Rectangle 19.png";
import GroupAbout from "../../assets/GroupAbout.png";
import pngwing from "../../assets/pngwing 1.png";
import { fetchHeaders } from "../../redux/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchAboutContents } from "../../redux/aboutSlice";

const MainAbout = () => {
  const dispatch = useDispatch();
  const headers = useSelector((state) => state.headers.headers);
  const headersStatus = useSelector((state) => state.headers.status);
  const error = useSelector((state) => state.headers.error);

  const aboutContents = useSelector(
    (state) => state.aboutContents.aboutContents
  );
  const aboutContentsStatus = useSelector(
    (state) => state.aboutContents.status
  );

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (headersStatus === "idle") {
      dispatch(fetchHeaders());
    }
  }, [headersStatus, dispatch]);

  useEffect(() => {
    if (aboutContentsStatus === "idle") {
      dispatch(fetchAboutContents());
    }
  }, [aboutContentsStatus, dispatch]);

  if (headersStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  const aboutContent = headers?.find((title) => title.name === "about");

  if (aboutContentsStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  const aboutContentDetails = aboutContents?.find(
    (content) =>
      content.page === "about" && content.section?.name_en === "header"
  );

  const TTitle =
    language === "en"
      ? aboutContentDetails?.title_en
      : aboutContentDetails?.title_ar;

  const TDesc =
    language === "en"
      ? aboutContentDetails?.desc_en
      : aboutContentDetails?.desc_ar;

  const points = aboutContents?.filter(
    (content) =>
      content.page === "about" && content.section?.name_en === "point"
  );

  const aboutContentDetailsM = aboutContents?.find(
    (content) =>
      content.page === "about" && content.section?.name_en === "Our Mission"
  );

  const MTitle =
    language === "en"
      ? aboutContentDetailsM?.title_en
      : aboutContentDetailsM?.title_ar;

  const MDesc =
    language === "en"
      ? aboutContentDetailsM?.desc_en
      : aboutContentDetailsM?.desc_ar;

  const aboutContentDetailsE = aboutContents?.find(
    (content) =>
      content.page === "about" && content.section?.name_en === "expirience"
  );

  const ETitle =
    language === "en"
      ? aboutContentDetailsE?.title_en
      : aboutContentDetailsE?.title_ar;

  const EDesc =
    language === "en"
      ? aboutContentDetailsE?.desc_en
      : aboutContentDetailsE?.desc_ar;

  return (
    <>
      <div className="bgAbout"></div>
      <div className="px-24 max-lg:px-5 header mb-0" id="hero">
        <div className="text-center h-[60vh]">
          <h1 className="text-5xl max-lg:text-4xl mx-auto px-7 text-[#EFEFEF] font_math mt-44 lg:w-3/4">
            {/* {language == "en" ? aboutContent.value_en : aboutContent.value_ar} */}
            {aboutContent
              ? language === "en"
                ? aboutContent.value_en
                : aboutContent.value_ar
              : "DELVE INTO THE TWISTED WORLD OF OUR ESCAPE ROOMS"}
          </h1>
        </div>

        <div>
          <h2 className="text-4xl max-lg:text-2xl font_math font-semibold text-center px-7 text-[#EFEFEF]">
            {TTitle}
          </h2>
          <p className="text-[#E9E5DD] text-lg text-center my-3">{TDesc}</p>
        </div>

        <div className="grid grid-cols-3 max-xl:grid-cols-1 gap-6 mt-6 text-[#E9E5DD] text-lg">
          {points?.map((point, index) => (
            <div key={index} className="flex items-center justify-center">
              <img src={ghost} width="20px" className="mx-5" />
              <span>{language === "en" ? point.title_en : point.title_ar}</span>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-6 mt-6 py-14 relative lg:items-center">
          <div className="flex justify-end">
            <img
              src={GroupAbout}
              width="450px"
              className={`rounded-xl absolute ${language == "en" ? "-left-24 max-lg:-left-9 -top-32 max-lg:-top-20" : "-right-24 max-lg:-right-9 -top-96 max-lg:-top-52 rotate-180"} z-0 max-lg:w-[270px]`}
            />
            <img
              src={Rectangle}
              width="380px"
              className={`${language == "en" ? "lg:mr-20" : "lg:ml-20"} rounded-xl relative z-10 max-lg:w-[230px]`}
            />
          </div>
          <div>
            <h2 className="text-4xl max-lg:text-2xl font_math font-semibold text-[#EFEFEF]">
              {MTitle}
            </h2>
            <p className="text-[#E9E5DD] text-lg my-3">{MDesc}</p>
          </div>
        </div>

        <div className={`relative ${language == "ar" && "pb-20"}`}>
          <div className="flex items-start">
            <div className="text-[#F4EB19] border-4 border-[#F4EB19] p-7 max-lg:p-4 rounded-full font-bold text-4xl max-lg:text-2xl">
              3+
            </div>
            <div>
              <h2 className={`text-5xl max-lg:text-2xl font_math font-bold text-[#EFEFEF] ${language == "en" ? "ml-5" : "mr-5"} lg:w-2/3`}>
                {ETitle}
              </h2>
              <p className={`text-[#E9E5DD] text-lg my-3 ${language == "en" ? "ml-5 lg:mr-20" : "mr-5 lg:ml-20"}`}>
                {EDesc}
              </p>
            </div>
            <img
              src={pngwing}
              width="200px"
              className={`absolute ${language == "en" ? "-right-24 max-lg:-right-5" : "-left-24 max-lg:-left-5 rotate-180"} -top-20 max-lg:w-[100px]`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainAbout;
