import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicAxios } from '../api';

const initialState = {
  headers: [],
  status: 'idle',
  error: null,
};

export const fetchHeaders = createAsyncThunk('headers/fetchHeaders', async () => {
  const response = await publicAxios.get('/api/headers');
  return response.data;
});

const headerSlice = createSlice({
  name: 'headers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHeaders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHeaders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.headers = action.payload;
      })
      .addCase(fetchHeaders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default headerSlice.reducer;