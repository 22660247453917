import { configureStore } from "@reduxjs/toolkit";
import questionsReducer from "./faqSlice";
import roomsReducer from "./roomSlice";
import contactReducer from "./contactSlice";
import headerReducer from "./headerSlice";
import homeReducer from "./homeSlice";
import aboutReducer from "./aboutSlice";
import contactContentsReducer from "./contactContentSlice";
import programsReducer from "./programsSlice";
import storiesReducer from "./storiesSlice";
import authReducer from "./authSlice";

export const store = configureStore({
  reducer: {
    questions: questionsReducer,
    rooms: roomsReducer,
    contacts:contactReducer,
    headers:headerReducer,
    homeContents:homeReducer,
    contactContents:contactContentsReducer,
    aboutContents:aboutReducer,
    programs: programsReducer,
    stories: storiesReducer,
    auth: authReducer,
  },
});

export default store;
