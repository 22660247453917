import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import qm2 from "../../assets/qm2.png";
import pngwing5 from "../../assets/pngwing5.png";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Service from "./Service";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeContents } from "../../redux/homeSlice";
import { useTranslation } from "react-i18next";
import { fetchPrograms } from "../../redux/programsSlice";

const OurServices = () => {
  const dispatch = useDispatch();
  const homeContents = useSelector((state) => state.homeContents.homeContents);
  const homeContentsStatus = useSelector((state) => state.homeContents.status);
  const error = useSelector((state) => state.homeContents.error);

  const programs = useSelector((state) => state.programs.programs);
  const programsStatus = useSelector((state) => state.programs.status);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (homeContentsStatus === "idle") {
      dispatch(fetchHomeContents());
    }
  }, [homeContentsStatus, dispatch]);

  useEffect(() => {
    if (programsStatus === "idle") {
      dispatch(fetchPrograms());
    }
  }, [programsStatus, dispatch]);

  // if (homeContentsStatus === "failed") {
  //   return (
  //     <div className="text-center text-red-500">
  //       <p>An error occurred. {error}</p>
  //     </div>
  //   );
  // }

  if (programsStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  const homeContentDetails = homeContents?.find(
    (content) =>
      content.page === "home" &&
      content.section?.name_en === "Check our services"
  );

  const STitle =
    language === "en"
      ? homeContentDetails?.title_en
      : homeContentDetails?.title_ar;

  const SDesc =
    language === "en"
      ? homeContentDetails?.desc_en
      : homeContentDetails?.desc_ar;

  const bestPrograms = programs.slice(0, 3);

  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  return (
    <>
      <div className="rooms-section-container relative">
        <img
          src={qm2}
          width="130px"
          className="absolute right-0 -top-14 max-lg:w-[70px]"
        />
        <div className="px-24 max-lg:px-5">
          <h2 className="text-4xl max-lg:text-2xl font_math font-bold primary_color mt-20">
            {STitle}
          </h2>
          <p className="text-lg text-[#E9E5DD] mt-5 w-3/4 max-lg:w-full">
            {SDesc}
          </p>

          <div className="grid grid-cols-3 max-xl:grid-cols-1 gap-8 my-10 max-lg:mt-5 relative z-10">
            {bestPrograms.map((program, index) => (
              <Service
                key={index}
                id={program.id}
                image={`${process.env.REACT_APP_BASE_URL}${program.image}`}
                name={language == "en" ? program.name_en : program.name_ar}
                desc={truncateText(language == "en" ? program.desc_en : program.desc_ar, 50)}
                min={program.min_player}
                max={program.max_player}
                time={program.time}
              />
            ))}
          </div>

          <img
            src={pngwing5}
            width="280px"
            className="absolute left-5 max-lg:left-0 -bottom-0 max-lg:bottom-10 z-0 max-lg:w-[100px]"
          />

          <div className="flex justify-center mx-auto mb-10">
            <Link
              to="/services"
              className="sign-up px-2 rounded-3xl border-2 border-[#F4EB19]"
            >
              <button className="btn py-3 px-5 text-[#F4EB19] tracking-wider flex items-center">
                {t("VIEW_MORE")}
                {language == "en" ? (
                  <RightOutlined className="ml-10 size-4" />
                ) : (
                  <LeftOutlined className="mr-10 size-4" />
                )}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServices;
