import React, { useEffect } from "react";
import cardpicture from "../assets/cardpicture.png";
import "../styles/Services.css";
import players from "../assets/players.png";
import clock from "../assets/clock.png";
import ghost from "../assets/ghost.png";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Cardcomponentreverse = ({ id, title, desc, min, max, time , subdata }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === `#${id}`) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location, id]);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  return (
    <div id={id}>
      <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-6 mt-3 py-14 px-12 relative lg:items-center ">
        <div>
          <div className="card-body pr-8">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">
              {desc}
            </p>
            <div>
              <div className="flex justify-center mt-10">
                <div className="flex items-center mr-5">
                  <img src={players} className="team-goals-img" alt="..." />
                  <p className="team-goals-text card-text">{min}-{max} players</p>
                </div>
                <div className="flex items-center">
                  <img src={clock} className="team-goals-img" alt="..." />
                  <p className="team-goals-text card-text">{time} mins</p>
                </div>
              </div>
            </div>

            <div>
              <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-6 lg:justify-between mt-5">
                {subdata ? (
                  subdata.map((data, index) => (
                    <div className="flex items-center mr-5">
                      <img src={ghost} className="team-goals-img" alt="..." />{" "}
                      <p className="team-goals-text card-text">
                        {language == "en" ? data.title_en : data.title_ar}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No data added</p>
                )}
              </div>
            </div>

          </div>
        </div>
        <div>
          <img
            src={cardpicture}
            className="img-fluid rounded-start"
            alt="..."
          />
        </div>
      </div>
    </div>
  );
};

export default Cardcomponentreverse;
