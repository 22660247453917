import React from "react";
import "../../styles/sign.css";
import FormInput from "../FormInput";
const ResetPassword = () => {
  return (
    <>
      <div className="h-[100vh]">
        <div className="bgSign">
          <div>
            <div className="small-container">
              <h2 className="text-center resetFont text-[#efefef] pt-[10%] mb-[8px] text-3xl font-semibold">
                RESET PASSWORD
              </h2>
              <h5 className="text-center text-sm font-normal h-register">
                Provide your registered email address
              </h5>
              <form className="container">
                <div className="flex justify-center">
                  <div className="w-2/5">
                    <FormInput
                      placeholder="Email Address"
                      aria-label="Email Address"
                      className="input"
                    />
                  </div>
                </div>
                <div className="text-center mt-5">
                  <button
                    type="submit"
                    className="btn btn-primary  py-4 px-8 font-semibold tracking-widest "
                  >
                    SEND RESET MAIL
                  </button>
                </div>
              </form>

              <h5 className="text-center text-xs  hstyle">
                Don’t have an account?{" "}
                <span className="underline">SIGN UP</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
