import React, { useState } from "react";
import "../styles/news.css";
import FormInput from "./FormInput";
import "../styles/form-comment.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createContact } from "../redux/contactFormSlice";

const LabelForm = ({ label, ...props }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    vision: "",
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    dispatch(createContact({ ...formData }));
    console.log({ ...formData });
  };

  return (
    <div>
      <form className="container md:w-3/4 mx-auto">
        <div className="flex flex-wrap justify-center mb-3">
          <div className="w-full md:w-1/2 px-2 mb-3 md:mb-0">
            <div className="relative">
              <FormInput
                placeholder="Sohaila"
                aria-label="First name"
                className="border rounded w-full py-2 px-3"
              />
              <label
                className={`input-label ${
                  language == "ar" && "input-label-ar"
                }`}
              >
                {t("First_name")}
              </label>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-2">
            <div className="relative">
              <FormInput
                placeholder="Ali"
                aria-label="Last name"
                className="border rounded w-full py-2 px-3"
              />
              <label
                className={`input-label ${
                  language == "ar" && "input-label-ar"
                }`}
              >
                {t("Last_name")}
              </label>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center mb-3">
          <div className="w-full md:w-1/2 px-2 mb-3 md:mb-0">
            <div className="relative">
              <FormInput
                placeholder="Sohailaahmed456@gmail.com"
                aria-label="Email Addess"
                className="border rounded w-full py-2 px-3"
              />
              <label
                className={`input-label ${
                  language == "ar" && "input-label-ar"
                }`}
              >
                {t("Email_Addess")}
              </label>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-2">
            <div className="relative">
              <FormInput
                placeholder="01012345678"
                aria-label="mobile number"
                className="border rounded w-full py-2 px-3"
              />
              <label
                className={`input-label ${
                  language == "ar" && "input-label-ar"
                }`}
              >
                {t("mobile_number")}
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-center mb-3">
          <div className="w-full px-2">
            <div className="relative">
              <FormInput
                placeholder={t("Subject_comments_questions")}
                aria-label="Subject / comments / questions"
                className="border rounded w-full py-2 px-3"
              />
              <label
                className={`input-label ${
                  language == "ar" && "input-label-ar"
                }`}
              >
                {t("Subject_comments_questions")}
              </label>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button type="submit" className="btn  btn-lg local py-4 px-16 ">
            {t("SEND_MESSAGE")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LabelForm;
