import React, { useState } from "react";
import "../../styles/sign.css";
import FormInput from "../FormInput";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Input, message } from "antd";
import { useDispatch } from "react-redux";
import { registerUser } from "../../redux/authSlice";

const SignUp = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      firstname,
      lastname,
      email,
      phone,
      password,
    };

    try {
      await dispatch(registerUser(userData)).unwrap();
      message.success(t("signed up"));
    } catch (error) {
      message.error(t("Failed to sign up"));
    }
  };

  return (
    <>
      <div className="h-[100vh]">
        <div className="bgSign">
          <div className="container mx-auto p-4">
            <h2 className="text-center headStyle">CREATE AN ACCOUNT</h2>

            <form
              className="w-full max-w-2xl mx-auto container"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <Input
                    placeholder="First name"
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="MyInput appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <Input
                    placeholder="Last name"
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    className="MyInput appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <Input
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="MyInput appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <Input
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="MyInput appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <Input.Password
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="MyInput appearance-none  w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  />
                </div>
              </div>
              <div className="text-center mt-6">
                <button
                  className="btn btn-primary py-4 px-16 font-semibold tracking-widest"
                  type="submit"
                >
                  SIGN UP
                </button>
              </div>
            </form>

            <h5 className="text-center text-gray-700 mt-4 hstyle">
              Already have an account?{" "}
              <Link to="/signin" className="underline">
                SIGN IN
              </Link>
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
