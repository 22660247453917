import React, { useEffect } from 'react'
import Newsview from '../components/Newsview';
import Newsviewtaps from '../components/Newsviewtaps';
import NewsForm from '../components/NewsForm';
import { useTranslation } from 'react-i18next';
import { fetchStories } from '../redux/storiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import "../styles/viewtab.css";
import { Tabs } from 'antd';

const NewsView = () => {

  const dispatch = useDispatch();
  const stories = useSelector((state) => state.stories.stories);
  const storiesStatus = useSelector((state) => state.stories.status);

  useEffect(() => {
    if (storiesStatus === "idle") {
      dispatch(fetchStories());
    }
  }, [storiesStatus, dispatch]);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const { id } = useParams();
  const storyId = parseInt(id);
  const story = stories.find(p => p.id === parseInt(id));

  const onChange = (key) => {
    console.log(key);
  };

  const items = story?.plans?.map((plan, index) => ({
    key: (index + 1).toString(),
    label: plan.title_en,
    children: (
      <p className="text-justify text-lg mt-5">
        {plan.desc_en}
      </p>
    ),
  })) || [];

  return (
    <div className="px-32 max-lg:px-5">
      <Newsview name={story ? language == "en" ? story.name_en : story.name_ar : "The name"} />
      <div className="bg-newviewtab ">
        <Tabs defaultActiveKey="1" items={items} centered onChange={onChange} />;
      </div>
      
      <NewsForm />
    </div>
  )
}

export default NewsView