import React, { useState } from "react";
import "../../styles/sign.css";
import FormInput from "../FormInput";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/authSlice";
import { Input } from "antd";

const Signin = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { isLoading, error, user } = useSelector((state) => state.auth || {});

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = { email, password };
    dispatch(loginUser(userData));
  };

  return (
    <div className="h-[100vh]">
      <div className="bgSign">
        <div className="small-container">
          <h2 className="text-center headStyle text-3xl">SIGN BACK IN</h2>

          <form className="container" onSubmit={handleSubmit}>
            <div className="flex justify-center mb-3">
              <div className="w-full md:w-1/3">
                <Input
                  className=" MyInput input"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="flex justify-center mb-3">
              <div className="w-full md:w-1/3">
                <Input.Password
                  className=" MyInput input"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div className="flex justify-center mb-3">
              <div className="w-full md:w-1/3">
                <Link to="/reset" className="hstyle-Signin">
                  forgot password?
                </Link>
              </div>
            </div>
            <div className="text-center mt-5">
              <button
                type="submit"
                className="btn btn-primary py-4 px-16 font-semibold tracking-widest"
              >
                SIGN IN
              </button>
            </div>
          </form>

          <h5 className="text-center hstyle">
            Don’t have an account?{" "}
            <Link to="/signup" className="underline">
              SIGN UP
            </Link>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Signin;
