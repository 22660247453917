import React from "react";
import Signin from "../components/login/Signin";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

const SignIn = () => {

  const { isAuthenticated } = useSelector((state) => state.auth || {});

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  console.log(isAuthenticated);

  return (
    <>
      <Signin />

      {/* <Newsview /> */}
    </>
  );
};

export default SignIn;
