import React, { useEffect } from "react";
import Servicestitle from "./Servicestitle";
import Cardcomponent from "./Cardcomponent";
import Singlecard from "./Singlecard";
import "../styles/Services.css";
import Cardcomponentreverse from "./Cardcomponentreverse";
import Servicesubtitle from "./Servicesubtitle";
import Servicesminititle from "./Servicesminititle";
import "../styles/news.css";
import FormInput from "./FormInput";
import "../styles/form-comment.css";

import GalleryImages from "./GalleryImages";
import InputDropdown from "./InputDropdown";
import LabelForm from "./LabelForm";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchHeaders } from "../redux/headerSlice";
import { fetchPrograms } from "../redux/programsSlice";
import { fetchStories } from "../redux/storiesSlice";

const Servicescomponent = ({ label, ...props }) => {
  const dispatch = useDispatch();
  const headers = useSelector((state) => state.headers.headers);
  const headersStatus = useSelector((state) => state.headers.status);
  const error = useSelector((state) => state.headers.error);

  const programs = useSelector((state) => state.programs.programs);
  const programsStatus = useSelector((state) => state.programs.status);

  const stories = useSelector((state) => state.stories.stories);
  const storiesStatus = useSelector((state) => state.stories.status);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (headersStatus === "idle") {
      dispatch(fetchHeaders());
    }
  }, [headersStatus, dispatch]);

  useEffect(() => {
    if (programsStatus === "idle") {
      dispatch(fetchPrograms());
    }
  }, [programsStatus, dispatch]);

  useEffect(() => {
    if (storiesStatus === "idle") {
      dispatch(fetchStories());
    }
  }, [storiesStatus, dispatch]);

  if (headersStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  if (programsStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  if (storiesStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  const servicesContent = headers?.find((title) => title.name === "services");

  const upcomingStory = stories?.filter((content) => content.type === "upcome");
  const newStory = stories?.filter((content) => content.type === "new");
  const popularStory = stories?.filter((content) => content.type === "popular");

  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  return (
    <>
      <div className="bgAbout"></div>
      <div>
        <div className="text-center h-[60vh]  mt-44">
          <Servicestitle
            headtitle={
              servicesContent
                ? language === "en"
                  ? servicesContent.value_en
                  : servicesContent.value_ar
                : "UNVEILING UNFORGETTABLE ESCAPADES"
            }
          />
        </div>

        <GalleryImages />

        <div>
          <div className="text-center text-white lg:mt-9 text-4xl max-lg:text-xl">
            <h1>Enjoy our diverse programs</h1>
          </div>

          {programs.map((program, index) => {
            return index % 2 === 0 ? (
              <Cardcomponent
                key={program.id}
                id={program.id}
                image={`${process.env.REACT_APP_BASE_URL}${program.image}`}
                title={language == "en" ? program.name_en : program.name_ar}
                desc={language == "en" ? program.desc_en : program.desc_ar}
                min={program.min_player}
                max={program.max_player}
                time={program.time}
                subdata={program.subdata}
              />
            ) : (
              <Cardcomponentreverse
                key={program.id}
                id={program.id}
                image={`${process.env.REACT_APP_BASE_URL}${program.image}`}
                title={language == "en" ? program.name_en : program.name_ar}
                desc={language == "en" ? program.desc_en : program.desc_ar}
                min={program.min_player}
                max={program.max_player}
                time={program.time}
                subdata={program.subdata}
              />
            );
          })}
        </div>

        <Servicesubtitle headsubtitle="Escape room News" />

        <div className="px-32 max-lg:px-5">
          <Servicesminititle minititle="Upcoming (Worth the wait)" />

          <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-40 max-lg:gap-6">
            {upcomingStory?.map((story, index) => (
              <Singlecard
                key={story.id}
                link={story.id}
                name={language == "en" ? story.name_en : story.name_ar}
                desc={truncateText(
                  language == "en" ? story.desc_en : story.desc_ar,
                  50
                )}
              />
            ))}
          </div>
        </div>

        <div className="px-32 max-lg:px-5">
          <Servicesminititle minititle="New releases (Worth the try)" />

          <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-40 max-lg:gap-6">
            {newStory?.map((story, index) => (
              <Singlecard
                key={story.id}
                link={story.id}
                name={language == "en" ? story.name_en : story.name_ar}
                desc={truncateText(
                  language == "en" ? story.desc_en : story.desc_ar,
                  50
                )}
              />
            ))}
          </div>
        </div>

        <div className="px-32 max-lg:px-5">
          <Servicesminititle minititle="Popular stories" />

          <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-40 max-lg:gap-6">
            {popularStory?.map((story, index) => (
              <Singlecard
                key={story.id}
                link={story.id}
                name={language == "en" ? story.name_en : story.name_ar}
                desc={truncateText(
                  language == "en" ? story.desc_en : story.desc_ar,
                  50
                )}
              />
            ))}
          </div>
        </div>

        <Servicesubtitle headsubtitle="Need more details?" />

        <div className="mt-5">
          <LabelForm />
        </div>
        <div className="text-center my-5">
          <button className="btn btn-primary btn-lg flex justify-center mx-auto px-4 py-2">
            SEND COMMENT
          </button>
        </div>
      </div>
    </>
  );
};

export default Servicescomponent;
