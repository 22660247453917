import React from "react";
import "../styles/Services.css";
const Servicesubtitle = ({headsubtitle}) => {
  return (
    <div className="container-services-subtitle">
      <div className="overlay">
      <div className="content-services-subtitle ">
        <h1>{headsubtitle}</h1>
      </div>
      </div>
    </div>
  );
};

export default Servicesubtitle;