import React, { useEffect }from "react";
import { Link } from "react-router-dom";
import Room from "./Room";
// import qoute3 from "../assets/qoute3.png";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchRooms } from "../../redux/roomSlice";
import { useTranslation } from "react-i18next";
import { fetchHomeContents } from "../../redux/homeSlice";

const Rooms_section = () => {
  const dispatch = useDispatch();
  const rooms = useSelector((state) => state.rooms.rooms);
  const roomsStatus = useSelector((state) => state.rooms.status);
  const error = useSelector((state) => state.rooms.error);

  useEffect(() => {
    if (roomsStatus === "idle") {
      dispatch(fetchRooms());
    }
  }, [roomsStatus, dispatch]);

  const bestRooms = rooms.slice(0, 3);

  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  const homeContents = useSelector((state) => state.homeContents.homeContents);
  const homeContentsStatus = useSelector((state) => state.homeContents.status);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (homeContentsStatus === "idle") {
      dispatch(fetchHomeContents());
    }
  }, [homeContentsStatus, dispatch]);

  if (homeContentsStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  const homeContentDetails = homeContents?.find(
    (content) => content.page === "home" && content.section?.name_en === "Try our rooms"
  );

  const TTitle =
    language === "en" ? homeContentDetails?.title_en : homeContentDetails?.title_ar;

  const TDesc =
    language === "en" ? homeContentDetails?.desc_en : homeContentDetails?.desc_ar;

  return (
    <>
      <div className="rooms-section-container bg_room_section relative">
        <div className="px-24 max-lg:px-5">
          <h2 className="text-4xl max-lg:text-2xl text-center font_math font-bold primary_color mt-20">
            {TTitle}
          </h2>
          <p className="text-lg text-center text-[#E9E5DD] mt-5">
            {TDesc}
          </p>

          <div className="grid grid-cols-3 max-xl:grid-cols-1 gap-8 my-10 max-lg:my-5">
            {bestRooms.map((room, index) => (
              <Room
                activeLevels={10}
                key={index}
                name={language == "en" ? room.name_en : room.name_ar}
                desc={truncateText(language == "en" ? room.desc_en : room.desc_ar, 50)}
                min={room.min_players}
                max={room.max_players}
                time={room.time_in_minutes}
                image={`${process.env.REACT_APP_BASE_URL}${room.image}`}
              />
            ))}
          </div>

          <div className="flex justify-center mx-auto">
            <Link
              to="/rooms"
              className="sign-up px-2 rounded-3xl border-2 border-[#F4EB19]"
            >
              <button className="btn py-3 px-3 text-[#F4EB19] tracking-wider flex items-center">
                {t('VIEW_ALL_ROOMS')}
                {
                  language == "en" ? <RightOutlined className="ml-3 size-4" /> : <LeftOutlined className="mr-3 size-4"/>
                }
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rooms_section;
