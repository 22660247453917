import axios from 'axios';
import { PrivateAxios } from '../api';

const API_URL = 'https://escaproom-be.dtagdev.com/api';

const register = (userData) => {
  const formData = new FormData();
  Object.keys(userData).forEach((key) => {
    formData.append(key, userData[key]);
  });
  return axios.post(`${ API_URL }/sign_up`, formData);
};

const login = (userData) => {
  return axios.post(`${ API_URL }/login`, userData);
};

const createProfile = (userData) => {
  return PrivateAxios.post(`${ API_URL }/updateprofile`, userData);
};

const resetPassword = (userData) => {
  return PrivateAxios.post(`${ API_URL }/restpassword`, userData);
};

const authAPI = {
  register,
  createProfile,
  login,
  resetPassword,
};

export default authAPI;