import React from "react";
import { Rate } from "antd";
import players from "../../assets/players.png";
import clock from "../../assets/clock.png";
import sketelon from "../../assets/skeleton.png";
import level2 from "../../assets/level2.png";
import ghost from "../../assets/ghost.png";
import people from "../../assets/people.png";
import "../../styles/room-view.css";
import HardLevels from "./HardLevels";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RoomViewInfo = ({rate , desc , min_player , max_player, type , time}) => {

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  return (
    <div>
      <div className="room-view-rate">
        <Rate
          disabled
          allowHalf
          defaultValue={rate}
          className="custom-rate text-4xl flex justify-center mt-10 text-center"
        />
      </div>
      <p className="text-justify content-plan ">
        {desc}
      </p>
      <div className="flex justify-evenly mt-10">
        <div className="flex items-center ">
          <img src={players} className="team-goals-img" alt="..." />{" "}
          <p className={`team-goals-text card-text ${language == "en" ? "ml-2" : "mr-2"}`}>{min_player}-{max_player} {t('players')}</p>
        </div>
        <div className="flex items-center">
          <img src={sketelon} className="team-goals-img" alt="..." />{" "}
          <p className={`team-goals-text card-text ${language == "en" ? "ml-2" : "mr-2"}`}>{type}</p>
        </div>
        <div className="flex items-center">
          <img src={clock} className="team-goals-img" alt="..." />{" "}
          <p className={`team-goals-text card-text ${language == "en" ? "ml-2" : "mr-2"}`}>{time} {t('mins')}</p>
        </div>
      </div>
      <div className=" flex  items-center justify-center mt-10">
        <HardLevels activeLevels={6} />
      </div>
      <div className="pl-8 my-10">
        <h3 className="font-roboto font-semibold text-2xl text-[#efefef]  mb-5">
          {t('You_like_room')}
        </h3>
        <div className="flex  items-center my-4 ">
          <img src={ghost} className="h-6 mx-4" />
          <p className="font-roboto font-normal text-lg text-[#E9E5DD]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
        <div className="flex  items-center my-4 ">
          <img src={ghost} className="h-6 mx-4" />
          <p className="font-roboto font-normal text-lg text-[#E9E5DD]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
      </div>
      <div className="flex justify-center space-x-16 ">
        <div className="flex flex-col items-center justify-center">
          <span className="material-symbols-outlined mx-auto">person</span>
          <h2 className="font-special font-bold text-4xl text-[#DAD7C1]">
            350 EGP / 4 {t('player')}
          </h2>
        </div>
        <div className="flex flex-col items-center justify-center">
          <span className="material-symbols-outlined mx-auto">person</span>

          <h2 className=" font-special font-bold text-4xl text-[#DAD7C1]">
            350 EGP / 4 {t('player')}
          </h2>
        </div>
      </div>
      <Link to="/booknow">
        <div className="text-center mt-5">
          <button className="btn btn-primary font-base racking-wider py-4 px-16 text-base rounded my-5">
             {t('BOOK_NOW')}
          </button>
        </div>
      </Link>
    </div>
  );
};

export default RoomViewInfo;
