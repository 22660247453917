import React, { useEffect, useState } from "react";
import "../styles/main.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Menu, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import logo from "../assets/logo.png";
import en from "../assets/en.png";
import ar from "../assets/ar.png";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/authSlice";
// import { useTranslation } from 'react-i18next';

const { SubMenu } = Menu;

const Nav = () => {
  //   const { t, i18n } = useTranslation();

  //   const { language } = i18n;

  let Links = [
    { name: "About Us", name_ar: "عنا", link: "/about" },
    { name: "Rooms", name_ar: "الغرف", link: "/rooms" },
    { name: "Services", name_ar: "خدماتنا", link: "/services" },
    { name: "Contact Us", name_ar: "تواصل معنا", link: "/contact" },
  ];

  const [open, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  useEffect(() => {
    if (i18n.language === "ar") {
      document.body.setAttribute("dir", "rtl");
    } else {
      document.body.setAttribute("dir", "ltr");
    }
  }, [i18n.language]);

  const { isAuthenticated } = useSelector((state) => state.auth || {});

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/signup');
  };

  return (
    <div
    // dir={language == "ar" && 'ltr'}
    >
      <div className="nav flex items-center justify-between bg-transparent py-4 px-24 max-lg:px-5 max-lg:hidden lg:flex relative z-10">
        <Link
          to="/"
          className="font-bold text-3xl max-lg:text-xl cursor-pointer flex items-center"
          style={{ fontFamily: "Cinzel Decorative" }}
        >
          <img src={logo} alt="logo" width="190" />
        </Link>
        <div className="mx-auto flex items-center ">
          <ul
            //   dir={language == "ar" && 'rtl'}
            className={`flex mx-auto items-center md:static md:z-auto mb-0 z-[-1] left-0 md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in`}
          >
            {Links.map((link) => (
              <li
                key={link.name}
                className="mx-4 text-lg my-0 max-lg:my-7 font-[ Cinzel Decorative]"
              >
                <NavLink
                  to={link.link}
                  className={({ isActive }) =>
                    isActive
                      ? "hover:#F4EB19 active:#F4EB19 duration-500 text-[#F4EB19]"
                      : "hover:#AD834E active:#AD834E duration-500 text-white"
                  }
                >
                  {language == "en" ? link.name : link.name_ar}
                </NavLink>
              </li>
            ))}
            <div>
              {language == "en" ? (
                <button
                  className="text-white text-lg ml-3 flex items-center"
                  onClick={() => changeLanguage("ar")}
                >
                  <img src={ar} alt="logo" className="w-[25px]" />
                  <span className="ml-2">Arabic</span>
                </button>
              ) : (
                <button
                  className="text-white text-lg ml-3 flex items-center"
                  onClick={() => changeLanguage("en")}
                >
                  <img src={en} alt="logo" className="w-[25px]" />
                  <span className="mx-2">English</span>
                </button>
              )}
            </div>
          </ul>
        </div>

        {isAuthenticated ? (
          <button onClick={handleLogout} className="px-2 rounded-3xl border-2 border-[#F4EB19] p-2 px-6 text-[#F4EB19]">Sign out</button>
        ) : (
          <Link
            to="/signup"
            className="sign-up px-2 rounded-3xl border-2 border-[#F4EB19]"
          >
            <button className="btn p-2 px-6 text-[#F4EB19]">
              {t("SIGN_UP")}
            </button>
          </Link>
        )}
      </div>

      <div className="nav flex items-center justify-between py-4 max-lg:px-5 lg:hidden max-lg:flex relative z-10">
        <Link
          to="/"
          className="font-bold text-3xl max-lg:text-xl cursor-pointer flex items-center"
          style={{ fontFamily: "Cinzel Decorative" }}
        >
          <img src={logo} alt="logo" width="140" />
        </Link>

        <div>
          <Button
            type="primary"
            className="bg-transparent"
            onClick={showDrawer}
          >
            <MenuOutlined />
          </Button>
          <Drawer title="" placement="right" onClose={onClose} visible={open}>
            <Menu
              // mode="inline"
              className="border-e-0 bg-[#D6BA2A] text-white"
              //   dir={language == "ar" && 'rtl'}
            >
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "hover:#AD834E active:#AD834E duration-500 text-lg block mb-7 font-[ Cinzel Decorative] text-[#202020]"
                    : "hover:#AD834E active:#AD834E duration-500 text-lg block mb-7 font-[ Cinzel Decorative]"
                }
              >
                {t("Home")}
              </NavLink>
              {Links.map((link) => (
                <NavLink
                  to={link.link}
                  key={link.name}
                  className={({ isActive }) =>
                    isActive
                      ? "hover:#202020 active:#202020 duration-500 text-lg block mb-7 font-[ Cinzel Decorative] text-[#202020]"
                      : "hover:#202020 active:#202020 duration-500 text-lg block mb-7 font-[ Cinzel Decorative]"
                  }
                >
                  {language == "en" ? link.name : link.name_ar}
                </NavLink>
              ))}
            </Menu>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Nav;
