import React from "react";
import { Link } from "react-router-dom";
import clock from "../../assets/clock.png";
import pic2 from "../../assets/pic2.png";
import players from "../../assets/players.png";
import { useTranslation } from "react-i18next";

const Service = ({image , name , desc , min , max , time , id}) => {

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  return (
    <>
      <div className="relative bg-[#0A0A0A] p-8 max-lg:p-5">
        <img src={image} width="" className="" />

        <div className="mt-8 mb-4">
          <h2 className="text-2xl font-semibold text-[#F3DB29] mb-2">
            {name}
          </h2>
          <p className="text-lg text-[#E9E5DD] mt-3">
            {desc}
          </p>

          <div className="grid grid-cols-2 max-xl:grid-cols-2 gap-2 my-4 px-9 max-lg:px-6">
            <div className="flex items-center">
              <img src={players} width="15px" className="" />
              <span className="text-[#CDC9C1] mx-2">{min}-{max} {t('players')}</span>
            </div>

            <div className="flex items-center ml-2">
              <img src={clock} width="15px" className="" />
              <span className="text-[#CDC9C1] mx-2">{time} {t('mins')}</span>
            </div>
          </div>

          <div className="flex justify-center mx-auto mt-7">
            <Link
              to={`/services#${id}`}
              className="py-3 px-9 max-lg:px-6 rounded-3xl bg-[#C7A807] text-[#EFEFEF] tracking-wider text-lg"
            >
              {t("DETAILS")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
