import React from "react";
import "../../styles/viewtab.css";
import { Tabs } from "antd";
import "../../styles/viewtab.css";
import RoomSwiper from "./RoomSwiper";
import RoomViewInfo from "./RoomViewInfo";
import { useTranslation } from "react-i18next";

const RoomViewTab = ({
  rate,
  desc,
  min_player,
  max_player,
  type,
  time,
  image,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "PLAN A",
      children: (
        <>
          <h2 className="text-center font-roboto-slab font-extrabold text-4xl text-[#efefef] mb-14 mt-8">
            {t("Photo_gallery")}
          </h2>
          <div dir="ltr">
            <RoomSwiper image={image} />
          </div>
          <RoomViewInfo
            rate={rate}
            desc={desc}
            min_player={min_player}
            max_player={max_player}
            type={type}
            time={time}
          />
        </>
      ),
    },
    {
      key: "2",
      label: "PLAN B",
      children: <p className="text-justify content-plan">B</p>,
    },
  ];

  return (
    <>
      <div
        className={`bg-newviewtab ${
          language == "ar" && "bg-newviewtab-ar"
        } RoomViewTab mt-8 px-20 max-lg:px-5`}
      >
        <Tabs defaultActiveKey="1" items={items} centered onChange={onChange} />
      </div>
    </>
  );
};
export default RoomViewTab;
