import React from 'react'
import ContactHome from '../components/Contact/ContactHome'
import Servicesubtitle from '../components/Servicesubtitle'
import SwiperComponents from '../components/Contact/SwiperComponents'
import Faq from '../components/Contact/Faq'
import { useTranslation } from 'react-i18next'
const ContactUs = () => {

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  return (
    <>
      <ContactHome />
      <Servicesubtitle headsubtitle={language == "en" ? "They made it.. Trust in YOU" : "لقد نجحوا.. ثق فيك"} />
      <SwiperComponents />
      <Faq />
    </>
  )
}

export default ContactUs