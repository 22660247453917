import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicAxios } from '../api';

const initialState = {
  homeContents: [],
  status: 'idle',
  error: null,
};

export const fetchHomeContents = createAsyncThunk('homeContents/fetchHomeContents', async () => {
  const response = await publicAxios.get('/api/contents/home');
  return response.data;
});

const homeSlice = createSlice({
  name: 'homeContents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeContents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHomeContents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.homeContents = action.payload;
      })
      .addCase(fetchHomeContents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default homeSlice.reducer;