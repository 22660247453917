import React, { Suspense, useEffect } from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { Spin } from "antd";
import './i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(
//   <Suspense fallback={<div className="text-center my-56"><Spin size="large" /></div>}>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </Suspense>
// );