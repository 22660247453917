import React, { useEffect } from 'react'
import RoomViewTab from '../components/room-view/RoomViewTab'
import RoomViewHome from '../components/room-view/RoomViewHome'
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRooms } from '../redux/roomSlice';
import { useTranslation } from 'react-i18next';

const RoomView = () => {

  const dispatch = useDispatch();
  const rooms = useSelector((state) => state.rooms.rooms);
  const roomsStatus = useSelector((state) => state.rooms.status);
  const error = useSelector((state) => state.rooms.error);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (roomsStatus === "idle") {
      dispatch(fetchRooms());
    }
  }, [roomsStatus, dispatch]);

  const { id } = useParams();
  const roomId = parseInt(id);
  const room = rooms.find(p => p.id === parseInt(id));

  return (
    <>
      <RoomViewHome name={room
        ? language === "en"
          ? room.name_en
          : room.name_ar
        : "Asylum"} />
      <RoomViewTab
        rate={room ? room.rate : 0}
        name={room ? language == "en" ? room.name_en : room.name_ar : "Asylum"}
        desc={room ? language == "en" ? room.desc_en : room.desc_ar : "Asylum"}
        min_player={room ? room.min_players : 4}
        max_player={room ? room.max_players : 6}
        time={room ? room.time_in_minutes : 60}
        type={t("Horror")}
        image={`${process.env.REACT_APP_BASE_URL}${room?.image}`}
      />
    </>
  )
}

export default RoomView