import React, { useEffect } from "react";
import Servicestitle from "../Servicestitle";
import "../../styles/Services.css";
import "../../styles/contact.css";
import pin from "../../assets/pin.png";
import call from "../../assets/call.png";
import envelope from "../../assets/envelope.png";
import { Link } from "react-router-dom";
import { createFromIconfontCN } from "@ant-design/icons";
import LabelForm from "../LabelForm";
import Servicesubtitle from "../Servicesubtitle";
import SwiperComponents from "./SwiperComponents";
import Faq from "./Faq";
import { useDispatch, useSelector } from "react-redux";
import { fetchContacts } from "../../redux/contactSlice";
import { fetchHeaders } from "../../redux/headerSlice";
import { useTranslation } from "react-i18next";
import { fetchContactContents } from "../../redux/contactContentSlice";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js",
});

const ContactHome = () => {
  const dispatch = useDispatch();
  const contactContents = useSelector(
    (state) => state.contactContents.contactContents
  );
  const contactContentsStatus = useSelector(
    (state) => state.contactContents.status
  );
  const error = useSelector((state) => state.contacts.error);
  const headers = useSelector((state) => state.headers.headers);
  const headersStatus = useSelector((state) => state.headers.status);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (contactContentsStatus === "idle") {
      dispatch(fetchContactContents());
    }
  }, [contactContentsStatus, dispatch]);

  useEffect(() => {
    if (headersStatus === "idle") {
      dispatch(fetchHeaders());
    }
  }, [headersStatus, dispatch]);

  if (contactContentsStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  const contactContent = headers?.find((title) => title.name === "contact");

  const aboutContentDetailsH = contactContents?.find(
    (content) =>
      content.page === "contact" && content.section?.name_en === "header"
  );

  const HTitle =
    language === "en"
      ? aboutContentDetailsH?.title_en
      : aboutContentDetailsH?.title_ar;

  const aboutContentDetailsE = contactContents?.find(
    (content) =>
      content.page === "contact" && content.section?.name_en === "Email"
  );

  const ETitle =
    language === "en"
      ? aboutContentDetailsE?.title_en
      : aboutContentDetailsE?.title_ar;

  const aboutContentDetailsP = contactContents?.find(
    (content) =>
      content.page === "contact" && content.section?.name_en === "Phone number"
  );

  const PTitle =
    language === "en"
      ? aboutContentDetailsP?.title_en
      : aboutContentDetailsP?.title_ar;

  const aboutContentDetailsA = contactContents?.find(
    (content) =>
      content.page === "contact" && content.section?.name_en === "address"
  );

  const ATitle =
    language === "en"
      ? aboutContentDetailsA?.title_en
      : aboutContentDetailsA?.title_ar;

  return (
    <>
      <div className="bgAbout "></div>
      <div className="text-center h-[60vh] max-lg:h-[30vh]  mt-44 max-lg:mt-20">
        <Servicestitle
          headtitle={
            contactContent
              ? language === "en"
                ? contactContent.value_en
                : contactContent.value_ar
              : "REACH OUT & UNLOCK THE UNKNOWN"
          }
        />
      </div>

      <div className="get-touch mb-5">
        <h2 className="lg:mx-28 max-lg:px-5 max-lg:text-base">{HTitle}</h2>
        <div className="lg:p-12 max-lg:my-8">
          <div className="items-center justify-between mx-32 max-lg:mx-5 lg:flex align-center  relative z-20">
            {ETitle && (
              <Link
                to={`mailto:${ETitle}`}
                className={`flex items-center  mr-3 max-lg:mr-1`}
              >
                <img src={envelope} className="size-6" />
                {/* <MailFilled className={`size-3 `} /> */}
                <span
                  className={`icon-text ${language == "en" ? "ml-3" : "mr-3"} `}
                >
                  {ETitle}
                </span>
              </Link>
            )}

            {PTitle && (
              <Link
                to={`tel:${PTitle}`}
                className={`flex items-center  mr-3 max-lg:mr-1 max-lg:mt-3`}
              >
                {/* <PhoneFilled className={`size-3 `} /> */}
                <img src={call} className="size-6" />
                <span
                  className={`icon-text ${language == "en" ? "ml-3" : "mr-3"}`}
                >
                  {PTitle}
                </span>
              </Link>
            )}

            <Link
              to="/"
              className={`flex items-center mr-3 max-lg:mr-1 max-lg:mt-3`}
            >
              {/* <EnvironmentTwoTone className={`size-3 `} /> */}
              <img src={pin} className="size-6" />
              <span
                className={`icon-text ${language == "en" ? "ml-3" : "mr-3"}`}
              >
                {ATitle}
              </span>
            </Link>
          </div>
        </div>
        <LabelForm />
      </div>

      {/* <Servicesubtitle headsubtitle="They made it.. Trust in YOU" />
      <SwiperComponents />
      <Faq /> */}
    </>
  );
};

export default ContactHome;
