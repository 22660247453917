import React from 'react'
import Faq from '../components/Contact/Faq';
import Advertisement from '../components/Advertisement';
import "../styles/about.css";
import "../styles/contact.css";
import MainAbout from '../components/About/MainAbout';
import SwiperComponents from '../components/Contact/SwiperComponents';
import WhyEscape from '../components/About/WhyEscape';

const About = () => {
    return (
        <>
            <MainAbout />
            <SwiperComponents />
            <WhyEscape />
            <Faq />
            <Advertisement />
        </>
    )
}

export default About
