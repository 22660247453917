import axios from 'axios';

export const publicAxios = axios.create({
    baseURL: 'https://escaproom-be.dtagdev.com',
    timeout: 10000,
    headers: { 'Content-Type': 'application/json' },
});

export const PrivateAxios = axios.create({
  baseURL: 'https://escaproom-be.dtagdev.com',
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
});

PrivateAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['token'] = `${token}`;
    }
    console.log('Updated Config headers:', config.headers);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// PrivateAxios.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       config.headers['Authorization'] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

