import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicAxios } from '../api';

const initialState = {
  contactContents: [],
  status: 'idle',
  error: null,
};

export const fetchContactContents = createAsyncThunk('contactContents/fetchContactContents', async () => {
  const response = await publicAxios.get('/api/contents/contact');
  return response.data;
});

const contactContentSlice = createSlice({
  name: 'contactContents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactContents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchContactContents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contactContents = action.payload;
      })
      .addCase(fetchContactContents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default contactContentSlice.reducer;