import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import "../../styles/Faq.css";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuestions } from "../../redux/faqSlice";
import { useTranslation } from "react-i18next";
import { fetchAboutContents } from "../../redux/aboutSlice";

const Faq = () => {
  const [collapsed, setCollapsed] = useState(false);
  let [open, setOpen] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    {
      collapsed ? <PlusOutlined /> : <MinusOutlined />;
    }
  };
  const customExpandIcon = ({ isActive }) =>
    isActive ? (
      <MinusOutlined className="plus-icon mt-4" />
    ) : (
      <PlusOutlined className="plus-icon mt-4" />
    );

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const dispatch = useDispatch();
  const questions = useSelector((state) => state.questions.questions);
  const questionsStatus = useSelector((state) => state.questions.status);
  const error = useSelector((state) => state.questions.error);

  const aboutContents = useSelector(
    (state) => state.aboutContents.aboutContents
  );
  const aboutContentsStatus = useSelector(
    (state) => state.aboutContents.status
  );

  useEffect(() => {
    if (questionsStatus === "idle") {
      dispatch(fetchQuestions());
    }
  }, [questionsStatus, dispatch]);


  useEffect(() => {
    if (aboutContentsStatus === "idle") {
      dispatch(fetchAboutContents());
    }
  }, [aboutContentsStatus, dispatch]);

  if (aboutContentsStatus === "failed") {
    return (
      <div className="text-center text-red-500">
        <p>An error occurred. {error}</p>
      </div>
    );
  }

  const aboutContentDetailsF = aboutContents?.find(
    (content) =>
      content.page === "about" &&
      content.section?.name_en === "Faq Header"
  );

  const FTitle =
    language === "en"
      ? aboutContentDetailsF?.title_en
      : aboutContentDetailsF?.title_ar;

  const FDesc =
    language === "en"
      ? aboutContentDetailsF?.desc_en
      : aboutContentDetailsF?.desc_ar;

  return (
    <div
      className="px-24 lg:mx-12 lg:my-16 max-lg:mt-6 bg-[#121212] max-lg:px-5 "
      style={{
        fontWeight: "700",
        fontFamily: "Comfortaa",
      }}
    >
      <h2 className="text-4xl text-center faq-title">{FTitle}</h2>
      <p className="text-lg text-center faq-subtitle my-6 lg:mx-44">
        {FDesc}
      </p>
      <div className="  flex justify-center mx-auto">
        <Collapse
          defaultActiveKey={["1"]}
          inlineCollapsed={collapsed}
          // items={items}
          expandIcon={customExpandIcon}
          expandIconPosition="right"
          bordered={false}
          style={{
            fontWeight: "400",
            fontFamily: "Comfortaa",
          }}
          className="text-2xl faq-text text-blue-600  max-lg:text-xl w-3/4 max-lg:w-full"
        >
          {questions.map((question, index) => (
            <Collapse.Panel
              header={language == "en" ? question.q_en : question.q_ar}
              key={index + 1}
            >
              <h4 className="text-xl max-lg:text-base lg:pl-6 lg:pr-10">
                {language == "en" ? question.a_en : question.a_ar}
              </h4>
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default Faq;
