import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Terror from "./Terror";
import sleeping from "../../assets/sleeping-mask.png";
import lock from "../../assets/lock.png";
import stopwatch from "../../assets/stopwatch.png";
import door from "../../assets/door.png";
import terror from "../../assets/terror.png";
import { fetchHomeContents } from "../../redux/homeSlice";

const Trapped = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const dispatch = useDispatch();
  
  const homeContents = useSelector((state) => state.homeContents.homeContents);
  const homeContentsStatus = useSelector((state) => state.homeContents.status);
  const error = useSelector((state) => state.headers.error);

  const { i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    if (homeContentsStatus === "idle") {
      dispatch(fetchHomeContents());
    }
  }, [homeContentsStatus, dispatch]);

  if (homeContentsStatus === "failed") {
    return <div className="text-center text-red-500"><p>An error occurred. {error}</p></div>;
  }

  // Function to get content by section
  const getSectionContent = (sectionNameEn) => {
    return homeContents?.find(
      (content) => content.page === "home" && content.section?.name_en === sectionNameEn
    );
  };

  const allTabs = [
    {
      key: "1",
      label: <img src={sleeping} width="60px" className="w-[60px] max-lg:w-[39px]" />,
      content: getSectionContent("First tab"),
    },
    {
      key: "2",
      label: <img src={lock} width="60px" className="w-[60px] max-lg:w-[35px]" />,
      content: getSectionContent("Second tab"),
    },
    {
      key: "3",
      label: <img src={stopwatch} width="60px" className="w-[60px] max-lg:w-[35px]" />,
      content: getSectionContent("Third tab"),
    },
    {
      key: "4",
      label: <img src={door} width="60px" className="w-[60px] max-lg:w-[35px]" />,
      content: getSectionContent("Fourth tab"),
    },
  ];

  return (
    <div className="mt-10 relative h-full Trapped">
      <div className="flex items-center justify-center mb-4">
        <Tabs activeKey={String(currentTab + 1)} onChange={(key) => setCurrentTab(parseInt(key) - 1)}>
          {allTabs.map((tab) => (
            <Tabs.TabPane tab={tab.label} key={tab.key}>
              {tab.content ? (
                <Terror
                  number={`0${tab.key}`}
                  title={language === "en" ? tab.content.title_en : tab.content.title_ar}
                  desc={language === "en" ? tab.content.desc_en : tab.content.desc_ar}
                  // image={
                  //   tab.content.image
                  //     ? `${process.env.REACT_APP_BASE_URL}${tab.content.image}`
                  //     : terror
                  // }
                  image={`${process.env.REACT_APP_BASE_URL}${tab.content.image}`}
                />
              ) : (
                <p>No content available for this tab</p>
              )}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>

      {/* Pagination Dots */}
      <div className={`flex justify-center  mt-4 absolute bottom-20 max-lg:-bottom-8 ${language == "en" ? "left-1/4 max-lg:left-32" : "right-1/4 max-lg:right-32"}`}>
        {allTabs.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentTab(index)}
            style={{
              width: currentTab === index ? "25px" : "10px",
              height: "8px",
              borderRadius: currentTab === index ? "10px" : "50%",
              backgroundColor: currentTab === index ? "#EDE755" : "#F6EFCA",
              cursor: "pointer",
            }}
            className="mx-1"
          />
        ))}
      </div>
    </div>
  );
};

export default Trapped;
